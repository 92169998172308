@import './src/style/foundation/typography';
@import './src/style/foundation/colors';
@import './src/style/foundation/spacings';

.table-row {
  @extend %font-sans-LG;

  td {
    height: 48px;
    padding-left: $spacing-SM;
    overflow: hidden;

    color: $text-dark-primary;
    white-space: nowrap;

    button {
      text-align: right;

      background: transparent;
      border: none;
      border-radius: 2px;

      &:hover {
        color: $dh-red;

        cursor: pointer;
      }
    }
  }

  &:nth-child(odd) td {
    background-color: #fafafa;
  }

  &:nth-child(even) td {
    background-color: white;
  }
}
