@import '../../../style/foundation/typography';
@import '../../../style/foundation/spacings';
@import '../../../style/foundation/colors';
@import '../../../style/foundation/shadows';
@import '../../../elements/Button';
@import '../../../elements/title';
@import '../../../elements/byline';

.nrwg-solution-column {
  @include respond-to('xl') {
    min-width: 440px;
  }
  z-index: 2;

  display: grid;
  grid-area: nrwg-products-column;
  grid-template-areas:
    'container'
    'actions';
  grid-template-rows: minmax(0, 1fr) 100px;
  grid-template-columns: auto;
  min-width: 380px;
  height: calc(100vh - 40px);
  min-height: $base-unit * 545;

  background-color: $light-grey;

  &__lock-button {
    grid-area: lock;

    background-color: transparent;
    border-left: 1px solid rgba(#000, 0.085);

    &:hover {
      border-left: 1px solid rgba(#000, 0.085);
    }
  }

  &__add-nrwg-element-button {
    position: relative;

    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;

    border: 1px solid rgba(#000, 0.15);
    border-radius: 4px;
    cursor: pointer;

    transition-duration: 0.25s;

    .result-item {
      margin: 0;

      visibility: hidden;
    }

    &:hover {
      @extend %shadow-elevation-1;
      background-color: #fff;
      border: 1px solid rgba(#000, 0);
    }
  }

  &__add-nrwg-element-button-icon {
    @extend %icon;
    margin-top: -2px;
    margin-right: $spacing-SM;

    color: $text-dark-disabled;
    font-size: 20px;
  }

  &__add-nrwg-element-button-label {
    @extend %display-sans-MD;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    width: 100%;
    padding-left: $spacing-MD;

    color: $text-dark-secondary;
  }

  &__title {
    @extend %display-sans-MD;
    display: flex;
    grid-area: title;
    align-items: center;
    margin-bottom: $spacing-MD * 1.5;

    color: $text-dark-secondary;
    line-height: 0px;
  }

  &__content {
    @include respond-to('lg') {
      padding: $spacing-LG $spacing-MD;
    }
    @include respond-to('xl') {
      padding: $spacing-LG * 1.5 $spacing-LG;
    }
    grid-area: container;
    padding: $spacing-LG * 1.5 $spacing-LG;
  }

  &__actions-seperator {
    display: inline-flex;
    grid-area: seperator;
    width: 1px;
    height: 40px;

    background-color: rgba(#000, 0.35);
  }

  &__actions {
    display: grid;
    grid-area: actions;
    grid-template-areas: 'download lock';
    grid-template-columns: minmax(auto, 1fr) auto;
    align-items: flex-start;
    width: 100%;

    background-color: white;
    border-top: 1px solid rgba(#000, 0.085);

    .download-dialog__button {
      @extend %font-sans-MD;
      display: flex;
      grid-area: download;
      margin-right: auto;
      margin-left: auto;

      font-weight: 500;

      background-color: transparent;
    }

    .nrwg-solution-column__lock-button,
    .download-dialog__button {
      @extend %font-sans-MD;
      flex-direction: row-reverse;
      justify-content: center;
      width: 100%;
      height: 74px;

      font-weight: 500;

      border-radius: 0;

      .button__icon {
        margin-right: $spacing-MD;
        margin-left: 0;

        font-size: 20px;
      }
    }

    &--locked {
      display: grid;
      grid-area: actions;
      grid-template-areas: 'download';
      grid-template-columns: 1fr;
      align-items: center;
    }
  }

  &__locked_icon {
    @include respond-to('lg') {
      font-size: 17px;
    }
    @include respond-to('xl') {
      font-size: 19px;
      line-height: 0px;
    }
    @extend %icon;
    margin-right: $spacing-SM;

    color: $text-dark-disabled;

    cursor: default;
  }

  &__wind-deflector-result {
    @extend %font-sans-MD;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $spacing-MD * 1.5;
    margin-bottom: $spacing-SM * 1.5;
    padding: $spacing-XS $spacing-XS $spacing-XS $spacing-SM;

    color: $text-dark-primary;
    font-weight: 400;

    background-color: $darker-grey;
  }

  &__application-drawing {
    text-decoration: none;

    .result-item:hover {
      .result-item__name {
        text-decoration: underline;
      }
    }

    .tile {
      cursor: pointer;
    }
  }
}
