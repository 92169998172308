@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';
@import '../../style/foundation/colors';

.test-phases {
  position: relative;

  &__copy-button {
    margin-left: $spacing-MD;
  }

  &__add-winddeflector-configuration {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing-SM;
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  &__button {
    width: 200px;
    margin-top: $spacing-LG;
  }

  &__button-roof {
    width: 100%;
    margin-top: $spacing-LG;
  }

  &__data-dialog {
    @include respond-to('xl') {
      height: calc(100% - 50px);
    }
    margin-top: -15px;
    margin-bottom: -15px;
  }

  &__data-dialog-application-overview {
    .dialog__content {
      padding: $spacing-MD $spacing-MD;
    }
  }

  &__data-dialog-application-overview-button {
    margin-bottom: $spacing-MD;
  }

  &__data-dialog-layout-basic {
    display: grid;
    grid-template-areas:
      'name name name name . .'
      'distance-to-hindge distance-to-hindge distance-to-hindge distance-to-hindge . .'
      'switch switch . . . .';
    max-width: 80%;
  }

  &__select {
    padding: $spacing-SM $spacing-SM 0 $spacing-SM;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    button {
      @extend %font-sans-MD;
      display: inline-flex;
      align-items: center;

      color: $text-dark-secondary;
      text-decoration: underline;

      background: none;
      border: none;
      cursor: pointer;
    }

    &--highlight-selected {
      option {
        color: black;

        background: white;
        -webkit-tap-highlight-color: transparent;
      }
    }
  }

  &__data-dialog-layout-further-information {
    display: grid;
    grid-row-gap: 0;
    grid-column-gap: 0;
    grid-template-areas:
      'select-row select-row select-row select-row select-row select-row'
      'edit-phases-content edit-phases-content edit-phases-content edit-phases-content edit-phases-content edit-phases-content';
    grid-template-rows: 228px 1fr;
    max-width: 100%;
    height: 100%;
    padding-top: $spacing-SM * 1.25;
    padding-right: 0;
    padding-bottom: $spacing-SM;
    padding-left: $spacing-SM;

    background: #e7eaed;

    select {
      @extend %font-sans-LG;
      width: 100%;
      height: 65%;
      overflow: auto;

      border: solid 1px $input-field-border;

      option {
        display: flex;
        align-items: center;
        height: 32px;
        padding-left: $spacing-MD;
      }
    }
  }

  &__edit-phases-row-system {
    display: grid;
    grid-column-gap: $spacing-XL;
    grid-template-areas: 'function-security function-security temperature heat load minGlassThickness';
    grid-template-rows: auto auto;
    grid-template-columns: auto auto auto 200px 200px 1fr;
  }

  &__edit-phases-row-series {
    display: grid;
    grid-row-gap: $spacing-XS * 1.25;
    grid-column-gap: $spacing-XS * 1.25;
    grid-template-areas: 'inputs profiles';
    grid-template-rows: auto;
    grid-template-columns: 1fr 2fr;
    height: 100%;
    padding: 0;

    input {
      max-height: 32px;
    }

    .test-phases__edit-phases-row-series-profiles {
      display: grid;
      grid-area: profiles;
      grid-template-rows: 250px 250px;
      grid-template-columns: auto auto;
    }
  }

  &__edit-phases-row-profile-type {
    display: grid;
    grid-row-gap: $spacing-XS * 1.25;
    grid-column-gap: $spacing-XS * 1.25;
    grid-template-areas: 'inputs profile-type profile-type profile-type . .';
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    padding: 0;

    input {
      max-height: 32px;
    }
  }

  &__edit-phases-row-application {
    display: grid;
    grid-row-gap: $spacing-XS * 1.25;
    grid-column-gap: $spacing-XS * 1.25;
    grid-template-areas: 'inputs application application application . .';
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    padding: 0;

    input {
      max-height: 32px;
    }
  }

  &__edit-phases-row-opening-direction {
    display: grid;
    grid-row-gap: $spacing-XS * 1.25;
    grid-column-gap: $spacing-XS * 1.25;
    grid-template-areas: 'inputs . . . . .';
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    padding: 0;

    input {
      max-height: 32px;
    }
  }

  &__edit-phases-row-profile-type-inputs {
    grid-area: inputs;
    padding: $spacing-LG $spacing-SM;
  }

  &__edit-phases-row-application-inputs {
    grid-area: inputs;
    padding: $spacing-LG $spacing-SM;
  }

  &__edit-phases-row-series-inputs {
    grid-area: inputs;
    padding: $spacing-LG $spacing-SM;
  }

  &__edit-phases-row-opening-direction-inputs {
    grid-area: inputs;
    padding: $spacing-LG $spacing-SM;
  }

  &__frame-profiles {
    grid-area: frame-profiles;
  }

  &__basic-profiles {
    grid-area: basic-profiles;
  }

  &__sash-profiles {
    grid-area: sash-profiles;
  }

  &__exchange-profiles {
    grid-area: exchange-profiles;
  }

  &__material {
    grid-area: material;
  }

  &__angle {
    grid-area: angle;
  }

  &__windload {
    grid-area: windload;
  }

  &__double-field-group {
    display: grid;
    grid-column-gap: $spacing-SM;
    grid-template-areas: 'title input-1 input-2';
    grid-template-columns: 160px 140px 160px;

    .input-field {
      display: grid;
      grid-column-gap: 10px;
      grid-template-areas: 'label field';
      grid-template-columns: 30px 80px;
      margin-bottom: $spacing-MD;

      .field-label {
        grid-area: label;

        text-align: right !important;
      }

      .input-field__field {
        grid-area: field;
      }
    }
  }

  &__tested-area {
    grid-area: tested-area;
  }

  &__title {
    grid-area: title;

    text-align: right;
  }

  &__input-1 {
    grid-area: input-1;
  }

  &__input-2 {
    grid-area: input-2;
  }

  &__table-head {
    th {
      position: sticky;
      top: 0;
      z-index: 2;

      background-color: #f8f8f8;
    }
  }

  &__table-container {
    height: 280px;
    overflow-y: auto;

    border: solid 1px $input-field-border;
  }

  &__drive-groups {
    grid-area: drive-groups;
  }

  &__drive-groups-select-group {
    width: 100%;
    height: 390px;
  }

  &__drive-families {
    grid-area: drive-families;

    table {
      width: 100%;
    }

    th {
      @extend %font-sans-MD;
      width: 100%;

      color: $text-dark-secondary;
      text-align: left;
    }

    tr {
      @extend %font-sans-MD;
      width: 100%;
      height: 36px;

      color: $text-dark-primary;

      &:nth-child(even) {
        background-color: white;
      }

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }

  &__edit-phases-row {
    grid-area: edit-phases-content;
    width: 100%;
    padding-top: $spacing-XS * 1.25;

    background-color: white;
  }

  &__function-security {
    grid-area: function-security;
  }

  &__temperature {
    grid-area: temperature;
  }

  &__heat {
    grid-area: heat;
  }

  &__loads {
    grid-area: load;
  }

  &__minGlassThickness {
    grid-area: minGlassThickness;
  }

  &__edit-phases-select-row {
    position: relative;

    display: grid;
    grid-area: select-row;
    grid-template-areas: 'select-system select-serie opening-direction application installation-type profile-type';
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    max-height: 10px;

    background: #e7eaed;
  }

  &__edit-phases-select-row-roof {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &__edit-phases-select-row-facade {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  &__select--active {
    background-color: white;
  }

  &__select-system {
    grid-area: select-system;
  }

  &__select-serie {
    grid-area: select-serie;
  }

  &__opening-direction {
    grid-area: opening-direction;
  }

  &__application {
    grid-area: application;
  }

  &__installation-type {
    grid-area: installation-type;
  }

  &__profile-type {
    grid-area: profile-type;
  }

  &__name {
    grid-area: name;
  }

  &__distance-to-hindge {
    grid-area: distance-to-hindge;
  }

  &__switch {
    grid-area: switch;
  }

  th {
    width: auto;
    max-width: 50px;
    padding-top: 0;
    overflow: hidden;
  }

  &__dialog {
    .mdc-dialog__container.dialog__container {
      width: 70vw;
      min-width: 1280px;
      height: 80vh;
      min-height: 740px;
      margin-bottom: 2vh;
    }
  }
}

.roof-test-phases__data-dialog.test-phases__data-dialog {
  .assign-component__assign-select {
    height: 750px;
  }
}
